import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Box from "../../../components/_styles/Box";
import { Card } from "../../../styles";
import Flex from "../../../components/_styles/Flex";
import PatientTable from "./PatientListView/PatientTable";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { ReactComponent as Search} from "../../../assets/searchIconGrey.svg";

import { getPatientsListViewAction, SET_PATIENTS_SEARCH_VALUE } from "../../../actions/radminUploads";
import { useFormik } from "formik";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import { useLocation } from "react-router";
const cancelIcon = `${process.env.PUBLIC_URL}/${'cancel-circled.svg'}`;


const FormContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 57px;
  align-item: space-between;
`;
const InputContainer = styled.div`
  // width: 100vw;
  // padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) {
    width: 350px;
    margin-top: 30px;
  }
`;
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  margin-right: 550px;
  color: green;
  min-width: 40px;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;
const CustomPlaceholder = styled.span`
  position: absolute;
  // margin-left: 100px;
  margin-right:120px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #666666;
  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
const Input = styled.input`
  width:618px;
  background: white;
  border: 1px solid #D2D3D7;
  border-radius: 5px;
  height: 38px;
  font: normal 16px "Roboto Condensed", sans-serif;
  padding-left:35px;
`;

const ClearButton = styled.button`
  position: absolute;
  margin-left: 550px;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  @media (max-width: 768px) {
    margin-left: -30px;
  }
`;

const PaginationButton = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #DFE0EB;
  padding-top:30px;
  padding-bottom:30px;
`;

const Header = styled.h1`
font-size:36px;
color:#0C2945;
font-weight:700;
font-family:Roboto Condensed;
margin-bottom:0px;
`



const PatientList = ({ history }) => {
  const dispatch = useDispatch();
  const itemsPortionSize = 10;
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [isActiveFirst, setIsActiveFirst] = useState(
    history.location.pathname.split("/")[4] === "patients");
  const inputRef = useRef(null);
  const patients = useSelector(state => state.radAdmin.patientsList)
  const patientsLoading = useSelector(state => state.radAdmin.patientsList.isLoading)
  const hasMoreItems = patients?.data?.length !== patients.totalCount;
  const location = useLocation();
  const getSerializedQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.toString();
  };


  let initialQueryString = getSerializedQueryParams();



  const onHidePlaceholder = () => {
    if (isActiveFirst) {
      setShowPlaceholder(false);
      return;
    }
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      searchValue: "",
      searchValueRad: "",
    },
    onSubmit: (values) => {
      if (isActiveFirst) {
        setPageNumber(1)
        dispatch({
          type: SET_PATIENTS_SEARCH_VALUE,
          payload: values.searchValue,
        });
        const params = new URLSearchParams(location.search);
        if(values.searchValue){
          params.set("search", values.searchValue);
        }else{
          params.delete("search");
        }
        let queryString = params.toString()
        dispatch(getPatientsListViewAction(1, queryString));
        history.push(`/auth/radAdmin/followUp/patients?${queryString}`); 
        return;
      }
    },
  });

  const onResetForm = () => {
    if (isActiveFirst) {
      formik.setFieldValue("searchValue", "");
       const params = new URLSearchParams(location.search);
      params.delete("search");
      let queryString = params.toString()
      dispatch(getPatientsListViewAction(1, queryString));

      history.push(`/auth/radAdmin/followUp/patients/?${queryString}`); 
      setShowPlaceholder(true);
    }
    setPageNumber(1)
  };

  const onLoadMore = () => {
    if (hasMoreItems) {

    dispatch(getPatientsListViewAction(pageNumber + 1, initialQueryString))
    setPageNumber((prevNumber) => prevNumber + 1);
    setDisplayItemsCount(displayItemsCount + itemsPortionSize);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let search = query.get("search");
    if (search) {
      formik.setFieldValue("searchValue", search);
      setShowPlaceholder(false);
      dispatch(getPatientsListViewAction(1, initialQueryString))
    } else {
      dispatch(getPatientsListViewAction(1,initialQueryString))
    }

  }, [])
  
  return (
    <Box p={'32px 50px'}>
      <FormContainer>
        <Header>Patients</Header>
        {isActiveFirst && (
          <form onSubmit={formik.handleSubmit}>
            <InputContainer>
                  <SearchIcon>
                    <Search />
                  </SearchIcon>
              {showPlaceholder && (
                <>
                  
                  <CustomPlaceholder onClick={onHidePlaceholder}>
                    {"Search by Patient Name, MRN, Email, or Phone Number"}
                  </CustomPlaceholder>
                </>
              )}
              <Input
                type="text"
                id={"searchValue"}
                name={"searchValue"}
                onFocus={onHidePlaceholder}
                onBlur={onShowPlaceholder}
                onChange={formik.handleChange}
                value={formik.values.searchValue}
                ref={inputRef}
              />
              {!showPlaceholder && formik.values.searchValue && (
                <ClearButton type={"reset"} onClick={onResetForm}>
                  <img  src={cancelIcon}/>
                </ClearButton>
              )}
            </InputContainer>
          </form>
        )}
      </FormContainer>
      <Card>
        <PatientTable
          patientsData={patients.data}
          isLoading={patientsLoading && pageNumber === 1}
          needMoreDetails={false}
          noDataRender={() => (
            <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
      
        {patients.data && patients?.data.length !== 0 && (
          <PaginationButton>
          <LoadMoreButton
            title="Load more patients"
            handleClick={onLoadMore}
            isDisabled={!hasMoreItems}
            isLoading={patients?.isLoading}
          />
          </PaginationButton>

        )}
      </Card>
      
    </Box>
  );
};

export default PatientList;

export  const PatientViewWithFeature = props => {
  return (
    <EnableFeature featureName={'enable-patient-view-list'} {...props}>
      <PatientList />
    </EnableFeature>
  );
};
