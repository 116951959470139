import moment from "moment";
import API from "../../libs/api";
import { showAlert } from "../radminUploads";
import { capitalize } from "lodash";

export const getCustomChartData =
  (selectedValues, startDate, endDate, filterValue) => async (dispatch) => {
    try {
      const {
        data_type,
        data_subset,
        frequency,
        chart_type,
        additional,
        group_by,
        flow_type,
      } = selectedValues;

      if (flow_type?.value === "compare") {
        let combinedRes = [];
        for (let i = 0; i < data_type.length; i++) {
          if (!data_subset[data_type[i].value]?.value) {
            continue;
          }

          let apiEndpoint = "";
          let timeStamp = "";
          let timeStampGte = "";
          let timeStampLte = "";
          let str = "";
          let groupBy = "";
          let status = group_by?.map((itm) => itm.value).join(",");
          let orderingFilter = "";
          let agg = "count";
          let extraParam = ``;

          if (data_type[i].value === "reports") {
            apiEndpoint = "/api/v1/report2/aggregate/";
            if(data_subset[data_type[i].value].value === "message_sent_created"){
            timeStamp = "message_sent_created";
            timeStampGte = "message_sent_created__gte";
            timeStampLte = "message_sent_created__lte";
            groupBy = "message_sent_created__";
            status = "";
          }
           else if (data_subset[data_type[i].value].value === "charge") {
              timeStamp = "initial_exam_date";
              timeStampGte = "initial_exam_date__gt";
              timeStampLte = "initial_exam_date__lt";
              groupBy = "initial_exam_date__";
              agg = "sum";
              extraParam = `&field=imagine_metadata__charge_amount`;
              status = "";
            } else {
              timeStamp = "initial_exam_date";
              timeStampGte = "initial_exam_date__gt";
              timeStampLte = "initial_exam_date__lt";
              groupBy = "initial_exam_date__";
              status = "";
            }

            const { reports } = additional;
            const { modality_type } = reports;

            for (let key in reports) {
              if (key === "modality_type") {
                continue;
              }
              let isNegation;
              if (key === "modality" && modality_type?.value === false) {
                isNegation = true;
              }

              let negated = isNegation ? "_ne" : "";
              if (Array.isArray(reports[key])) {
                str =
                  str +
                  reports[key]
                    .map((itm) => "&" + key + negated + "=" + itm.value)
                    .join("");
              } else if (reports[key]) {
                str = str + "&" + key + negated + "=" + reports[key].value;
              }
            }
          }
          if (data_type[i].value === "recommendations") {
            apiEndpoint = "/api/v1/recommendation/aggregate/";
            if (data_subset[data_type[i].value].value === "range_start") {
              timeStamp = "nlp_range_start";
              timeStampGte = "nlp_range_start_gte";
              timeStampLte = "nlp_range_start_lte";
              groupBy = "nlp_range_start__";
            } else if (data_subset[data_type[i].value].value === "range_end") {
              timeStamp = "nlp_range_end";
              timeStampGte = "nlp_range_end_gte";
              timeStampLte = "nlp_range_end_lte";
              groupBy = "nlp_range_end__";
            } else {
              timeStamp = "initial_exam_date";
              timeStampGte = "initial_exam_date_gte";
              timeStampLte = "initial_exam_date_lte";
              groupBy = "report__initial_exam_date__";
            }

            const { recommendations } = additional;
            const { modality_type, anatomy_type, report__modality_type, age, exam_code_type } =
              recommendations;
            
              if(age){
                recommendations["patient_age_group"]= age
              }
        

            for (let key in recommendations) {
              if (
                key === "modality_type" ||
                key === "anatomy_type" ||
                key === "report__modality_type" ||
                key === "age" ||
                key === "exam_code_type"
              ) {
                continue;
              }
              let isNegation;
              if (key === "modality" && modality_type?.value === false) {
                isNegation = true;
              }
              if (key === "anatomy" && anatomy_type?.value === false) {
                isNegation = true;
              }
              if (
                key === "report__modality" &&
                report__modality_type?.value === false
              ) {
                isNegation = true;
              }

              if (key === "exam_code" && exam_code_type?.value === false) {
                isNegation = true;
              }

              let negated = isNegation ? "_ne" : "";
              if (Array.isArray(recommendations[key])) {
                str =
                  str +
                  recommendations[key]
                    .map((itm) => "&" + key + negated + "=" + itm.value)
                    .join("");
              } else if (recommendations[key]) {
                if (
                  key === "initial_exam_date_gte" ||
                  key === "initial_exam_date_lte" ||
                  key === "nlp_range_start_gte" ||
                  key === "nlp_range_start_lte" ||
                  key === "nlp_range_end_gte" ||
                  key === "nlp_range_end_lte"
                ) {
                  str =
                    str +
                    "&" +
                    key +
                    negated +
                    "=" +
                    moment(recommendations[key].value).format("YYYY-MM-DD");
                } else {
                  str =
                    str +
                    "&" +
                    key +
                    negated +
                    "=" +
                    recommendations[key].value;
                }
              }
            }
          }

          if (data_type[i].value === "sms") {
            apiEndpoint = "/api/v1/sms_message/aggregate/";
            timeStamp = "created";
            timeStampGte = "created__gte";
            timeStampLte = "created__lte";
            groupBy = "created__";

            const { sms } = additional;

            for (let key in sms) {
              if (Array.isArray(sms[key])) {
                str =
                  str +
                  sms[key].map((itm) => "&" + key + "=" + itm.value).join("");
              } else if (sms[key]) {
                str = str + "&" + key + "=" + sms[key].value;
              }
            }
          }
          if (data_type[i].value === "emails") {
            apiEndpoint = "/api/v1/email-records/aggregate/";
            timeStamp = "created";
            timeStampGte = "created__gte";
            timeStampLte = "created__lte";
            groupBy = "created__";
            const { emails } = additional;

            for (let key in emails) {
              if (Array.isArray(emails[key])) {
                str =
                  str +
                  emails[key]
                    .map((itm) => "&" + key + "=" + itm.value)
                    .join("");
              } else if (emails[key]) {
                str = str + "&" + key + "=" + emails[key].value;
              }
            }
          }

          if (data_type[i].value === "notes") {
            apiEndpoint = "/api/v1/note/aggregate/";
            timeStamp = "created";
            timeStampGte = "note_created__gte";
            timeStampLte = "note_created__lte";
            groupBy = "created__";
            status = "task";

            const { notes } = additional;

            for (let key in notes) {
              if (Array.isArray(notes[key])) {
                str =
                  str +
                  notes[key].map((itm) => "&" + key + "=" + itm.value).join("");
              } else if (notes[key]) {
                str = str + "&" + key + "=" + notes[key].value;
              }
            }
          }

          if (chart_type?.value === "line" || chart_type?.value === "bar") {
            let group = groupBy ? `&group_by=${groupBy}` : "";

            if (frequency.value.includes("days")) {
              if (data_type[i].value === "recommendations") {
                group = status
                  ? group.replace("__", "") + "," + status
                  : group.replace("__", "");
                orderingFilter = `&order_by=${groupBy.replace("__", "")}`;
              } else {
                group = status ? group + "date" + "," + status : group + "date";
                orderingFilter = `&order_by=${groupBy}date`;
              }

              const getDaysInRange = (startDate, endDate) => {
                const start = moment(startDate);
                const end = moment(endDate);

                const days = [];

                let current = start.clone();

                while (current.isBefore(end) || current.isSame(end, "day")) {
                  days.push({
                    [data_type[i].value === "recommendations"
                      ? `${groupBy.replace("__", "")}`
                      : `${groupBy}date`]: current.format("YYYY-MM-DD"),
                  });
                  current.add(1, "day");
                }

                return days;
              };

              const daysInRange = getDaysInRange(startDate, endDate);

              const { data } = await API.getRequest(
                `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                  startDate
                ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(
                  endDate
                ).format(
                  "YYYY-MM-DD"
                )}${str}${group}${orderingFilter}${extraParam}`
              );

              const fillDataForDays = (daysInRange, apiData) => {
                return daysInRange.map((dayObj) => {
                  const match = apiData.filter(
                    (data) =>
                      data[
                        data_type[i].value === "recommendations"
                          ? `${groupBy.replace("__", "")}`
                          : `${groupBy}date`
                      ] ===
                      dayObj[
                        data_type[i].value === "recommendations"
                          ? `${groupBy.replace("__", "")}`
                          : `${groupBy}date`
                      ]
                  );
                  if (match.length > 0) {
                    return match;
                  } else {
                    return [
                      {
                        ...dayObj,
                        [selectedValues["data_subset"]?.["reports"]?.value !==
                        "charge"
                          ? "id__count"
                          : "imagine_metadata__charge_amount__sum"]: 0,
                      },
                    ];
                  }
                });
              };

              const filledData = fillDataForDays(daysInRange, data);

              let filledDataPreceding;

              let subtractNo =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? 12
                  : 0;
              let subtractType =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? "months"
                  : "days";

              let precedingData;
              if (selectedValues["compare_preceeding"]) {
                const { data } = await API.getRequest(
                  `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                    startDate
                  )
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")}&${timeStampLte}=${moment(
                    endDate || Date()
                  )
                    .subtract(subtractNo, subtractType)
                    .format(
                      "YYYY-MM-DD"
                    )}${str}${group}${orderingFilter}${extraParam}`
                );
                precedingData = data;
                const daysInRange = getDaysInRange(
                  moment(startDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD"),
                  moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")
                );
                filledDataPreceding = fillDataForDays(daysInRange, data);
                filledDataPreceding = filledDataPreceding.map((itm, idx) => {
                  return {
                    itm,
                    [data_type[i].value === "recommendations"
                      ? `${groupBy.replace("__", "")}`
                      : `${groupBy}date`]:
                      filledData[idx][0][
                        data_type[i].value === "recommendations"
                          ? `${groupBy.replace("__", "")}`
                          : `${groupBy}date`
                      ],
                    [data_type[i].value === "recommendations"
                      ? `prev_${groupBy.replace("__", "")}`
                      : `prev_${groupBy}date`]:
                      itm[0][
                        data_type[i].value === "recommendations"
                          ? `${groupBy.replace("__", "")}`
                          : `${groupBy}date`
                      ],
                  };
                });
              }

              if (
                data_type[i].value === "recommendations" ||
                data_type[i].value === "sms" ||
                data_type[i].value === "emails" ||
                data_type[i].value === "notes"
              ) {
                const groupedData = {};

                filledData.forEach((entry) => {
                  for (let y = 0; y < entry?.length; y++) {
                    const { status, id__count, anatomy, modality, task } =
                      entry[y];
                    let groupByDays =
                      data_type[i].value === "recommendations"
                        ? entry[y][groupBy.replace("__", "")]
                        : entry[y][groupBy + "date"];
                    if (!groupedData[groupByDays]) {
                      if (data_type[i].value === "recommendations") {
                        groupedData[groupByDays] = {
                          [groupBy.replace("__", "")]: groupByDays,
                          data: [],
                        };
                      } else {
                        groupedData[groupByDays] = {
                          [groupBy + "date"]: groupByDays,
                          data: [],
                        };
                      }
                    }

                    groupedData[groupByDays].data.push({
                      status,
                      id__count,
                      anatomy,
                      modality,
                      task,
                    });
                  }
                });

                const groupedDataList = Object.values(groupedData);
                combinedRes.push(
                  groupedDataList.map((itm) => {
                    return {
                      date:
                        data_type[i].value === "recommendations"
                          ? itm[groupBy.replace("__", "")]
                          : itm[groupBy + "date"],
                      data: [...itm.data],
                      label:
                        capitalize(data_type[i].value) +
                        ": " +
                        filterValue.value,
                      isPreceding: false,
                      category: data_type[i].value,
                      prevDate: "",
                    };
                  })
                );

                if (selectedValues["compare_preceeding"]) {
                  const groupedDataPreceeding = {};

                  filledDataPreceding.forEach((entry) => {
                    for (let y = 0; y < entry.itm?.length; y++) {
                      const { status, id__count, anatomy, modality, task } =
                        entry.itm[y];
                      let groupByDays =
                        data_type[i].value === "recommendations"
                          ? entry[groupBy.replace("__", "")]
                          : entry[groupBy + "date"];

                      let prevGroupByDays =
                        data_type[i].value === "recommendations"
                          ? entry["prev_" + groupBy.replace("__", "")]
                          : entry["prev_" + groupBy + "date"];
                      if (!groupedDataPreceeding[groupByDays]) {
                        if (data_type[i].value === "recommendations") {
                          groupedDataPreceeding[groupByDays] = {
                            [groupBy.replace("__", "")]: groupByDays,
                            data: [],
                            ["prev_" + groupBy.replace("__", "")]:
                              prevGroupByDays,
                          };
                        } else {
                          groupedDataPreceeding[groupByDays] = {
                            [groupBy + "date"]: groupByDays,
                            data: [],
                            ["prev_" + groupBy + "date"]: prevGroupByDays,
                          };
                        }
                      }

                      groupedDataPreceeding[groupByDays].data.push({
                        status,
                        id__count,
                        anatomy,
                        modality,
                        task,
                      });
                    }
                  });

                  const groupedDataListPreceeding = Object.values(
                    groupedDataPreceeding
                  );
                  combinedRes.push(
                    groupedDataListPreceeding.map((itm) => {
                      return {
                        date:
                          data_type[i].value === "recommendations"
                            ? itm[groupBy.replace("__", "")]
                            : itm[groupBy + "date"],
                        data: [...itm.data],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          "Preceding period",
                        isPreceding: true,
                        category: data_type[i].value,
                        prevDate:
                          data_type[i].value === "recommendations"
                            ? itm["prev_" + groupBy.replace("__", "")]
                            : itm["prev_" + groupBy + "date"],
                      };
                    })
                  );
                }
              } else {
                combinedRes.push(
                  filledData.map((itm) => {
                    for (let y = 0; y < itm.length; y++) {
                      return {
                        date: itm[y][groupBy + "date"],
                        data: [itm[y]],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          filterValue.value,
                        isPreceding: false,
                        category: data_type[i].value,
                      };
                    }
                  })
                );
                if (selectedValues["compare_preceeding"]) {
                  combinedRes.push(
                    filledDataPreceding.map((itm) => {
                      for (let y = 0; y < itm.itm.length; y++) {
                        return {
                          date: itm[groupBy + "date"],
                          data: itm.itm,
                          label:
                            capitalize(data_type[i].value) +
                            ": " +
                            "Preceding period",
                          isPreceding: true,
                          category: data_type[i].value,
                          prevDate: itm["prev_" + groupBy + "date"],
                        };
                      }
                    })
                  );
                }
              }
            } else if (frequency.value.includes("months")) {
              group = status
                ? group + "month" + "," + groupBy + "year" + "," + status
                : group + "month" + "," + groupBy + "year";
              orderingFilter = `&order_by=${groupBy}year,${groupBy}month`;

              let subtractNo =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? 12
                  : 0;
              let subtractType =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? "months"
                  : "days";

              const getMonthsInRange = (startDate, endDate) => {
                const start = moment(startDate);
                const end = moment(endDate);

                const months = [];

                let current = start.clone();

                while (current.isBefore(end) || current.isSame(end, "month")) {
                  months.push({
                    [`${groupBy}month`]: current.month() + 1,
                    [`${groupBy}year`]: current.year(),
                  });
                  current.add(1, "month");
                }

                return months;
              };

              const monthsInRange = getMonthsInRange(startDate, endDate);

              const { data } = await API.getRequest(
                `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                  startDate
                ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(
                  endDate
                ).format(
                  "YYYY-MM-DD"
                )}${str}${group}${orderingFilter}${extraParam}`
              );

              const fillDataForMonths = (monthsInRange, apiData) => {
                return monthsInRange.map((monthObj) => {
                  const match = apiData.filter(
                    (data) =>
                      data[`${groupBy}month`] === monthObj[`${groupBy}month`] &&
                      data[`${groupBy}year`] === monthObj[`${groupBy}year`]
                  );
                  if (match.length > 0) {
                    return match;
                  } else {
                    return [
                      {
                        ...monthObj,
                        [selectedValues["data_subset"]?.["reports"]?.value !==
                        "charge"
                          ? "id__count"
                          : "imagine_metadata__charge_amount__sum"]: 0,
                      },
                    ];
                  }
                });
              };
              const filledData = fillDataForMonths(monthsInRange, data);

              let filledDataPreceding;

              let precedingData;
              if (selectedValues["compare_preceeding"]) {
                const { data } = await API.getRequest(
                  `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                    startDate
                  )
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format(
                      "YYYY-MM-DD"
                    )}${str}${group}${orderingFilter}${extraParam}`
                );
                precedingData = data;
                const monthsInRange = getMonthsInRange(
                  moment(startDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD"),
                  moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")
                );
                filledDataPreceding = fillDataForMonths(monthsInRange, data);
                filledDataPreceding = filledDataPreceding.map((itm, idx) => {
                  return {
                    itm,
                    [`${groupBy}month`]: filledData[idx][0][`${groupBy}month`],
                    [`${groupBy}year`]: filledData[idx][0][`${groupBy}year`],
                    [`prev_${groupBy}month`]: itm[0][`${groupBy}month`],
                    [`prev_${groupBy}year`]: itm[0][`${groupBy}year`],
                  };
                });
              }

              function getMonthName(month) {
                const months = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];

                if (month >= 1 && month <= 12) {
                  return months[month - 1];
                } else {
                  return "Invalid Month";
                }
              }

              if (
                data_type[i].value === "recommendations" ||
                data_type[i].value === "sms" ||
                data_type[i].value === "emails" ||
                data_type[i].value === "notes"
              ) {
                const groupedData = {};

                filledData.forEach((entry, index) => {
                  for (let i = 0; i < entry?.length; i++) {
                    const { status, id__count, modality, anatomy, task } =
                      entry[i];
                    let groupByMonths = entry[i][groupBy + "month"];
                    let groupByYears = entry[i][groupBy + "year"];

                    if (!groupedData[groupByMonths + " " + groupByYears]) {
                      groupedData[groupByMonths + " " + groupByYears] = {
                        [groupBy + "month"]: groupByMonths,
                        data: [],
                        [groupBy + "year"]: groupByYears,
                      };
                    }

                    groupedData[groupByMonths + " " + groupByYears].data.push({
                      status,
                      id__count,
                      anatomy,
                      modality,
                      task,
                    });
                  }
                });

                const groupedDataList = Object.values(groupedData);

                combinedRes.push(
                  groupedDataList.map((itm) => {
                    return {
                      date:
                        getMonthName(itm[groupBy + "month"]) +
                        " " +
                        itm[groupBy + "year"],
                      data: [...itm.data],
                      label:
                        capitalize(data_type[i].value) +
                        ": " +
                        filterValue.value,
                      isPreceding: false,
                      category: data_type[i].value,
                      prevDate: "",
                    };
                  })
                );

                if (selectedValues["compare_preceeding"]) {
                  const groupedDataPreceeding = {};
                  filledDataPreceding.forEach((entry) => {
                    for (let i = 0; i < entry.itm?.length; i++) {
                      const { status, id__count, modality, anatomy, task } =
                        entry.itm[i];
                      let groupByMonths = entry[groupBy + "month"];
                      let groupByYears = entry[groupBy + "year"];
                      let prevGroupByMonths =
                        entry["prev_" + groupBy + "month"];
                      let prevGroupByYears = entry["prev_" + groupBy + "year"];

                      if (
                        !groupedDataPreceeding[
                          groupByMonths + " " + groupByYears
                        ]
                      ) {
                        groupedDataPreceeding[
                          groupByMonths + " " + groupByYears
                        ] = {
                          [groupBy + "month"]: groupByMonths,
                          data: [],
                          [groupBy + "year"]: groupByYears,
                          ["prev_" + groupBy + "month"]: prevGroupByMonths,
                          ["prev_" + groupBy + "year"]: prevGroupByYears,
                        };
                      }

                      groupedDataPreceeding[
                        groupByMonths + " " + groupByYears
                      ].data.push({
                        status,
                        id__count,
                        anatomy,
                        modality,
                        task,
                      });
                    }
                  });

                  const groupedDataListPreceeding = Object.values(
                    groupedDataPreceeding
                  );

                  combinedRes.push(
                    groupedDataListPreceeding.map((itm) => {
                      return {
                        date:
                          getMonthName(itm[groupBy + "month"]) +
                          " " +
                          itm[groupBy + "year"],
                        data: [...itm.data],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          "Preceding period",
                        isPreceding: true,
                        category: data_type[i].value,
                        prevDate:
                          getMonthName(itm["prev_" + groupBy + "month"]) +
                          " " +
                          itm["prev_" + groupBy + "year"],
                      };
                    })
                  );
                }
              } else {
                combinedRes.push(
                  filledData.map((itm) => {
                    for (let y = 0; y < itm.length; y++) {
                      return {
                        date:
                          getMonthName(itm[y][groupBy + "month"]) +
                          " " +
                          itm[y][groupBy + "year"],
                        data: [itm[y]],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          filterValue.value,
                        isPreceding: false,
                        category: data_type[i].value,
                      };
                    }
                  })
                );
                if (selectedValues["compare_preceeding"]) {
                  combinedRes.push(
                    filledDataPreceding.map((itm) => {
                      for (let y = 0; y < itm.itm.length; y++) {
                        return {
                          date:
                            getMonthName(itm[groupBy + "month"]) +
                            " " +
                            itm[groupBy + "year"],
                          data: itm.itm,
                          label:
                            capitalize(data_type[i].value) +
                            ": " +
                            "Preceding period",
                          isPreceding: true,
                          category: data_type[i].value,
                          prevDate:
                            getMonthName(itm["prev_" + groupBy + "month"]) +
                            " " +
                            itm["prev_" + groupBy + "year"],
                        };
                      }
                    })
                  );
                }
              }
            } else if (frequency.value.includes("weeks")) {
              group = status
                ? group + "week" + "," + groupBy + "year" + "," + status
                : group + "week" + "," + groupBy + "year";
              orderingFilter = `&order_by=${groupBy}year,${groupBy}week`;

              const getWeeksInRange = (startDate, endDate) => {
                const start = moment(startDate).startOf("week");
                const end = moment(endDate).endOf("week");

                const weeks = [];

                let current = start.clone();

                while (current.isBefore(end)) {
                  weeks.push({
                    [`${groupBy}week`]: current.isoWeek(),
                    [`${groupBy}year`]: current.year(),
                  });
                  current.add(1, "week");
                }

                return weeks;
              };

              const weeksInRange = getWeeksInRange(startDate, endDate);

              const fillDataForWeeks = (weeksInRange, apiData) => {
                return weeksInRange.map((weekObj) => {
                  const match = apiData.filter(
                    (data) =>
                      data[`${groupBy}week`] === weekObj[`${groupBy}week`] &&
                      data[`${groupBy}year`] === weekObj[`${groupBy}year`]
                  );
                  if (match.length > 0) {
                    return match;
                  } else {
                    return [
                      {
                        ...weekObj,
                        [selectedValues["data_subset"]?.["reports"]?.value !==
                        "charge"
                          ? "id__count"
                          : "imagine_metadata__charge_amount__sum"]: 0,
                      },
                    ];
                  }
                });
              };

              let subtractNo =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? 12
                  : 0;
              let subtractType =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? "months"
                  : "days";

              const { data } = await API.getRequest(
                `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                  startDate
                ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(
                  endDate
                ).format(
                  "YYYY-MM-DD"
                )}${str}${group}${orderingFilter}${extraParam}`
              );

              const filledData = fillDataForWeeks(weeksInRange, data);

              let filledDataPreceding;

              let precedingData;
              if (selectedValues["compare_preceeding"]) {
                const { data } = await API.getRequest(
                  `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                    startDate
                  )
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format(
                      "YYYY-MM-DD"
                    )}${str}${group}${orderingFilter}${extraParam}`
                );
                precedingData = data;
                const weeksInRangeRaw = getWeeksInRange(
                  moment(startDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD"),
                  moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")
                );

                const weeksInRangeRawFiltered =
                  weeksInRangeRaw.length !== weeksInRange.length
                    ? weeksInRangeRaw.slice(1)
                    : weeksInRangeRaw;

                filledDataPreceding = fillDataForWeeks(
                  weeksInRangeRawFiltered,
                  data
                );

                filledDataPreceding = filledDataPreceding.map((itm, idx) => {
                  return {
                    itm,
                    [`${groupBy}year`]: filledData[idx][0][`${groupBy}year`],
                    [`${groupBy}week`]: filledData[idx][0][`${groupBy}week`],
                    [`prev_${groupBy}year`]: itm[0][`${groupBy}year`],
                    [`prev_${groupBy}week`]: itm[0][`${groupBy}week`],
                  };
                });
              }

              function getDateFromWeek(weekNumber, year) {
                const startDate = moment()
                  .year(year)
                  .week(weekNumber)
                  .startOf("week")
                  .format("DD MMM YYYY");
                const endDate = moment()
                  .year(year)
                  .week(weekNumber)
                  .endOf("week")
                  .format("DD MMM YYYY");

                return `${startDate} - ${endDate}`;
              }
              if (
                data_type[i].value === "recommendations" ||
                data_type[i].value === "sms" ||
                data_type[i].value === "emails" ||
                data_type[i].value === "notes"
              ) {
                const groupedData = {};

                filledData.forEach((entry) => {
                  for (let i = 0; i < entry?.length; i++) {
                    const { status, id__count, anatomy, modality, task } =
                      entry[i];
                    let groupByWeeks = entry[i][groupBy + "week"];
                    let groupByYears = entry[i][groupBy + "year"];

                    if (!groupedData[groupByWeeks + " " + groupByYears]) {
                      groupedData[groupByWeeks + " " + groupByYears] = {
                        [groupBy + "week"]: groupByWeeks,
                        data: [],
                        [groupBy + "year"]: groupByYears,
                      };
                    }

                    groupedData[groupByWeeks + " " + groupByYears].data.push({
                      status,
                      id__count,
                      anatomy,
                      modality,
                      task,
                    });
                  }
                });

                const groupedDataList = Object.values(groupedData);
                combinedRes.push(
                  groupedDataList.map((itm) => {
                    return {
                      date: getDateFromWeek(
                        itm[groupBy + "week"],
                        itm[groupBy + "year"]
                      ),
                      data: [...itm.data],
                      label:
                        capitalize(data_type[i].value) +
                        ": " +
                        filterValue.value,
                      isPreceding: false,
                      category: data_type[i].value,
                      prevDate: "",
                    };
                  })
                );
                if (selectedValues["compare_preceeding"]) {
                  const groupedDataPreceeding = {};

                  filledDataPreceding.forEach((entry) => {
                    for (let i = 0; i < entry.itm?.length; i++) {
                      const { status, id__count, anatomy, modality, task } =
                        entry.itm[i];
                      let groupByWeeks = entry[groupBy + "week"];
                      let groupByYears = entry[groupBy + "year"];
                      let prevGroupByWeeks = entry["prev_" + groupBy + "week"];
                      let prevGroupByYears = entry["prev_" + groupBy + "year"];

                      if (
                        !groupedDataPreceeding[
                          groupByWeeks + " " + groupByYears
                        ]
                      ) {
                        groupedDataPreceeding[
                          groupByWeeks + " " + groupByYears
                        ] = {
                          [groupBy + "week"]: groupByWeeks,
                          data: [],
                          [groupBy + "year"]: groupByYears,
                          ["prev_" + groupBy + "week"]: prevGroupByWeeks,
                          ["prev_" + groupBy + "year"]: prevGroupByYears,
                        };
                      }

                      groupedDataPreceeding[
                        groupByWeeks + " " + groupByYears
                      ].data.push({
                        status,
                        id__count,
                        anatomy,
                        modality,
                        task,
                      });
                    }
                  });

                  const groupedDataListPreceeding = Object.values(
                    groupedDataPreceeding
                  );
                  combinedRes.push(
                    groupedDataListPreceeding.map((itm) => {
                      return {
                        date: getDateFromWeek(
                          itm[groupBy + "week"],
                          itm[groupBy + "year"]
                        ),
                        data: [...itm.data],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          "Preceding period",
                        isPreceding: true,
                        category: data_type[i].value,
                        prevDate: getDateFromWeek(
                          itm["prev_" + groupBy + "week"],
                          itm["prev_" + groupBy + "year"]
                        ),
                      };
                    })
                  );
                }
              } else {
                combinedRes.push(
                  filledData.map((itm) => {
                    for (let y = 0; y < itm.length; y++) {
                      return {
                        date: getDateFromWeek(
                          itm[y][groupBy + "week"],
                          itm[y][groupBy + "year"]
                        ),
                        data: [itm[y]],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          filterValue.value,
                        isPreceding: false,
                        category: data_type[i].value,
                      };
                    }
                  })
                );
                if (selectedValues["compare_preceeding"]) {
                  combinedRes.push(
                    filledDataPreceding.map((itm) => {
                      for (let y = 0; y < itm.itm.length; y++) {
                        return {
                          date: getDateFromWeek(
                            itm[groupBy + "week"],
                            itm[groupBy + "year"]
                          ),
                          data: itm.itm,
                          label:
                            capitalize(data_type[i].value) +
                            ": " +
                            "Preceding period",
                          isPreceding: true,
                          category: data_type[i].value,
                          prevDate: getDateFromWeek(
                            itm["prev_" + groupBy + "week"],
                            itm["prev_" + groupBy + "year"]
                          ),
                        };
                      }
                    })
                  );
                }
              }
            } else if (frequency.value.includes("years")) {
              group = status ? group + "year" + "," + status : group + "year";
              orderingFilter = `&order_by=${groupBy}year`;

              let subtractNo =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? 12
                  : 0;
              let subtractType =
                filterValue.value === "Last 7 days" ||
                filterValue.value === "Last 30 days" ||
                filterValue.value === "Last 3 months" ||
                filterValue.value === "Last 6 months" ||
                filterValue.value === "Last 12 months"
                  ? "months"
                  : "days";

              const getYearsInRange = (startDate, endDate) => {
                const start = moment(startDate).startOf("year");
                const end = moment(endDate).startOf("year");

                const years = [];

                let current = start.clone();

                while (current.isBefore(end) || current.isSame(end, "year")) {
                  years.push({ [`${groupBy}year`]: current.year() });
                  current.add(1, "year");
                }

                return years;
              };
              const yearsInRange = getYearsInRange(startDate, endDate);

              const { data } = await API.getRequest(
                `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                  startDate
                ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(
                  endDate
                ).format(
                  "YYYY-MM-DD"
                )}${str}${group}${orderingFilter}${extraParam}`
              );

              const fillDataForYears = (yearsInRange, apiData) => {
                return yearsInRange.map((monthObj) => {
                  const match = apiData.filter(
                    (data) =>
                      data[`${groupBy}year`] === monthObj[`${groupBy}year`]
                  );
                  if (match.length > 0) {
                    return match;
                  } else {
                    return [
                      {
                        ...monthObj,
                        [selectedValues["data_subset"]?.["reports"]?.value !==
                        "charge"
                          ? "id__count"
                          : "imagine_metadata__charge_amount__sum"]: 0,
                      },
                    ];
                  }
                });
              };
              const filledData = fillDataForYears(yearsInRange, data);

              let filledDataPreceding;

              let precedingData;
              if (selectedValues["compare_preceeding"]) {
                const { data } = await API.getRequest(
                  `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                    startDate
                  )
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format(
                      "YYYY-MM-DD"
                    )}${str}${group}${orderingFilter}${extraParam}`
                );

                precedingData = data;
                const yearsInRange = getYearsInRange(
                  moment(startDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD"),
                  moment(endDate)
                    .subtract(subtractNo, subtractType)
                    .format("YYYY-MM-DD")
                );
                filledDataPreceding = fillDataForYears(yearsInRange, data);
                filledDataPreceding = filledDataPreceding.map((itm, idx) => {
                  return {
                    itm,
                    [`${groupBy}year`]: filledData[idx][0][`${groupBy}year`],
                    [`prev_${groupBy}year`]: itm[0][`${groupBy}year`],
                  };
                });
              }

              if (
                data_type[i].value === "recommendations" ||
                data_type[i].value === "sms" ||
                data_type[i].value === "emails" ||
                data_type[i].value === "notes"
              ) {
                const groupedData = {};

                filledData.forEach((entry) => {
                  for (let i = 0; i < entry?.length; i++) {
                    const { status, id__count, anatomy, modality, task } =
                      entry[i];
                    let groupByYears = entry[i][groupBy + "year"];

                    if (!groupedData[groupByYears]) {
                      groupedData[groupByYears] = {
                        [groupBy + "year"]: groupByYears,
                        data: [],
                      };
                    }

                    groupedData[groupByYears].data.push({
                      status,
                      id__count,
                      anatomy,
                      modality,
                    });
                  }
                });

                const groupedDataList = Object.values(groupedData);
                combinedRes.push(
                  groupedDataList.map((itm) => {
                    return {
                      date: itm[groupBy + "year"],
                      data: [...itm.data],
                      label:
                        capitalize(data_type[i].value) +
                        ": " +
                        filterValue.value,
                      isPreceding: false,
                      category: data_type[i].value,
                      prevDate: "",
                    };
                  })
                );

                if (selectedValues["compare_preceeding"]) {
                  const groupedDataPreceeding = {};

                  filledDataPreceding.forEach((entry) => {
                    for (let i = 0; i < entry.itm?.length; i++) {
                      const { status, id__count, anatomy, modality, task } =
                        entry.itm[i];
                      let groupByYears = entry[groupBy + "year"];
                      let prevGroupByYears = entry["prev_" + groupBy + "year"];

                      if (!groupedDataPreceeding[groupByYears]) {
                        groupedDataPreceeding[groupByYears] = {
                          [groupBy + "year"]: groupByYears,
                          data: [],
                          ["prev_" + groupBy + "year"]: prevGroupByYears,
                        };
                      }

                      groupedDataPreceeding[groupByYears].data.push({
                        status,
                        id__count,
                        anatomy,
                        modality,
                      });
                    }
                  });

                  const groupedDataListPreceeding = Object.values(
                    groupedDataPreceeding
                  );
                  combinedRes.push(
                    groupedDataListPreceeding.map((itm) => {
                      return {
                        date: itm[groupBy + "year"],
                        data: [...itm.data],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          "Preceding period",
                        isPreceding: true,
                        category: data_type[i].value,
                        prevDate: itm["prev_" + groupBy + "year"],
                      };
                    })
                  );
                }
              } else {
                combinedRes.push(
                  filledData.map((itm) => {
                    for (let y = 0; y < itm.length; y++) {
                      return {
                        date: itm[y][groupBy + "year"],
                        data: [itm[y]],
                        label:
                          capitalize(data_type[i].value) +
                          ": " +
                          filterValue.value,
                        isPreceding: false,
                        category: data_type[i].value,
                      };
                    }
                  })
                );
                if (selectedValues["compare_preceeding"]) {
                  combinedRes.push(
                    filledDataPreceding.map((itm) => {
                      for (let y = 0; y < itm.itm.length; y++) {
                        return {
                          date: itm[groupBy + "year"],
                          data: itm.itm,
                          label:
                            capitalize(data_type[i].value) +
                            ": " +
                            "Preceding period",
                          isPreceding: true,
                          category: data_type[i].value,
                          prevDate: itm["prev_" + groupBy + "year"],
                        };
                      }
                    })
                  );
                }
              }
            }
          }
        }
        return combinedRes;
      } else {
        let apiEndpoint = "";
        let timeStamp = "";
        let timeStampGte = "";
        let timeStampLte = "";
        let str = "";
        let groupBy = "";
        let status = group_by?.map((itm) => itm.value).join(",");
        let orderingFilter = "";
        let agg = "count";
        let extraParam = ``;

        if (data_type.value === "reports") {
          apiEndpoint = "/api/v1/report2/aggregate/";
          if(data_subset.value === "message_sent_created"){
            timeStamp = "message_sent_created";
            timeStampGte = "message_sent_created__gte";
            timeStampLte = "message_sent_created__lte";
            groupBy = "message_sent_created__";
            status = "";
          }
          else if (data_subset.value === "charge") {
            timeStamp = "initial_exam_date";
            timeStampGte = "initial_exam_date__gt";
            timeStampLte = "initial_exam_date__lt";
            groupBy = "initial_exam_date__";
            agg = "sum";
            extraParam = `&field=imagine_metadata__charge_amount`;
            status = "";
          } else {
            timeStamp = "initial_exam_date";
            timeStampGte = "initial_exam_date__gt";
            timeStampLte = "initial_exam_date__lt";
            groupBy = "initial_exam_date__";
            status = "";
          }

          const { reports } = additional;
          const { modality_type } = reports;

          for (let key in reports) {
            if (key === "modality_type") {
              continue;
            }
            let isNegation;
            if (key === "modality" && modality_type?.value === false) {
              isNegation = true;
            }

            let negated = isNegation ? "_ne" : "";
            if (Array.isArray(reports[key])) {
              str =
                str +
                reports[key]
                  .map((itm) => "&" + key + negated + "=" + itm.value)
                  .join("");
            } else if (reports[key]) {
              str = str + "&" + key + negated + "=" + reports[key].value;
            }
          }
        }
        if (data_type.value === "recommendations") {
          apiEndpoint = "/api/v1/recommendation/aggregate/";
          if (data_subset.value === "range_start") {
            timeStamp = "nlp_range_start";
            timeStampGte = "nlp_range_start_gte";
            timeStampLte = "nlp_range_start_lte";
            groupBy = "nlp_range_start__";
          } else if (data_subset.value === "range_end") {
            timeStamp = "nlp_range_end";
            timeStampGte = "nlp_range_end_gte";
            timeStampLte = "nlp_range_end_lte";
            groupBy = "nlp_range_end__";
          } else {
            timeStamp = "initial_exam_date";
            timeStampGte = "initial_exam_date_gte";
            timeStampLte = "initial_exam_date_lte";
            groupBy = "report__initial_exam_date__";
          }

          const { recommendations } = additional;
          const { modality_type, anatomy_type, report__modality_type, age, exam_code_type } =
            recommendations;
 
            if(age){
              recommendations["patient_age_group"]= age
            }
      
        

          for (let key in recommendations) {
            if (
              key === "modality_type" ||
              key === "anatomy_type" ||
              key === "report__modality_type" || 
              key === "age" || 
              key === "exam_code_type" 
            ) {
              continue;
            }
            let isNegation;
            if (key === "modality" && modality_type?.value === false) {
              isNegation = true;
            }
            if (key === "anatomy" && anatomy_type?.value === false) {
              isNegation = true;
            }
            if (
              key === "report__modality" &&
              report__modality_type?.value === false
            ) {
              isNegation = true;
            }
            if (key === "exam_code" && exam_code_type?.value === false) {
              isNegation = true;
            }

            let negated = isNegation ? "_ne" : "";
            if (Array.isArray(recommendations[key])) {
              str =
                str +
                recommendations[key]
                  .map((itm) => "&" + key + negated + "=" + itm.value)
                  .join("");
            } else if (recommendations[key]) {
              if (
                key === "initial_exam_date_gte" ||
                key === "initial_exam_date_lte" ||
                key === "nlp_range_start_gte" ||
                key === "nlp_range_start_lte" ||
                key === "nlp_range_end_gte" ||
                key === "nlp_range_end_lte"
              ) {
                str =
                  str +
                  "&" +
                  key +
                  negated +
                  "=" +
                  moment(recommendations[key].value).format("YYYY-MM-DD");
              } else {
                str =
                  str + "&" + key + negated + "=" + recommendations[key].value;
              }
            }
          }
        }
        if (data_type.value === "radiologists") {
          apiEndpoint = "/api/v1/recommendation/aggregate/";
        }
        if (data_type.value === "patients") {
          apiEndpoint = "/api/v1/patient/aggregate/";
        }
        if (data_type.value === "referrers") {
          apiEndpoint = "/api/v1/recommendation/aggregate/";
        }
        if (data_type.value === "sms") {
          apiEndpoint = "/api/v1/sms_message/aggregate/";
          timeStamp = "created";
          timeStampGte = "created__gte";
          timeStampLte = "created__lte";
          groupBy = "created__";

          const { sms } = additional;

          for (let key in sms) {
            if (sms[key] && sms[key].value !== "all") {
              str = str + "&" + key + "=" + sms[key].value;
            }
          }
        }
        if (data_type.value === "emails") {
          apiEndpoint = "/api/v1/email-records/aggregate/";
          timeStamp = "created";
          timeStampGte = "created__gte";
          timeStampLte = "created__lte";
          groupBy = "created__";
          const { emails } = additional;

          for (let key in emails) {
            if (emails[key] && emails[key].value !== "all") {
              str = str + "&" + key + "=" + emails[key].value;
            }
          }
        }

        if (data_type.value === "notes") {
          apiEndpoint = "/api/v1/note/aggregate/";
          timeStamp = "created";
          timeStampGte = "note_created__gte";
          timeStampLte = "note_created__lte";
          groupBy = "created__";
          status = "";
        }
        if (data_type.value === "recommendations-pie") {
          apiEndpoint = "/api/v1/recommendation/aggregate/";
          const { "recommendations-pie": recommendationsPie } = additional;

          const { modality_type, anatomy_type, report__modality_type, age, exam_code_type } =
            recommendationsPie;
 
            if(age){
              recommendationsPie["patient_age_group"]= age
            }
      
        
          for (let key in recommendationsPie) {
            if (
              key === "modality_type" ||
              key === "anatomy_type" ||
              key === "report__modality_type" ||
              key === "age" ||
              key === "exam_code_type"
            ) {
              continue;
            }
            let isNegation;
            if (key === "modality" && modality_type?.value === false) {
              isNegation = true;
            }
            if (key === "anatomy" && anatomy_type?.value === false) {
              isNegation = true;
            }
            if (
              key === "report__modality" &&
              report__modality_type?.value === false
            ) {
              isNegation = true;
            }
            if (key === "exam_code" && exam_code_type?.value === false) {
              isNegation = true;
            }
            let negated = isNegation ? "_ne" : "";
            if (Array.isArray(recommendationsPie[key])) {
              str =
                str +
                recommendationsPie[key]
                  .map((itm) => "&" + key + negated + "=" + itm.value)
                  .join("");
            } else if (recommendationsPie[key]) {
              if (
                key === "initial_exam_date_gte" ||
                key === "initial_exam_date_lte" ||
                key === "nlp_range_start_gte" ||
                key === "nlp_range_start_lte" ||
                key === "nlp_range_end_gte" ||
                key === "nlp_range_end_lte"
              ) {
                str =
                  str +
                  "&" +
                  key +
                  negated +
                  "=" +
                  moment(recommendationsPie[key].value).format("YYYY-MM-DD");
              } else {
                str =
                  str +
                  "&" +
                  key +
                  negated +
                  "=" +
                  recommendationsPie[key].value;
              }
            }
          }
        }

        if (chart_type?.value === "line" || chart_type?.value === "bar") {
          let group = groupBy ? `&group_by=${groupBy}` : "";

          if (frequency.value.includes("days")) {
            if (data_type.value === "recommendations") {
              group = status
                ? group.replace("__", "") + "," + status
                : group.replace("__", "");
              orderingFilter = `&order_by=${groupBy.replace("__", "")}`;
            } else {
              group = status ? group + "date" + "," + status : group + "date";
              orderingFilter = `&order_by=${groupBy}date`;
            }
            const { data } = await API.getRequest(
              `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                startDate
              ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate).format(
                "YYYY-MM-DD"
              )}${str}${group}${orderingFilter}${extraParam}`
            );

            if (
              data_type.value === "recommendations" ||
              data_type.value === "sms" ||
              data_type.value === "emails"
            ) {
              const groupedData = {};

              data.forEach((entry) => {
                const { status, id__count, anatomy, modality } = entry;
                let groupByDays =
                  data_type.value === "recommendations"
                    ? entry[groupBy.replace("__", "")]
                    : entry[groupBy + "date"];
                if (!groupedData[groupByDays]) {
                  if (data_type.value === "recommendations") {
                    groupedData[groupByDays] = {
                      [groupBy.replace("__", "")]: groupByDays,
                      data: [],
                    };
                  } else {
                    groupedData[groupByDays] = {
                      [groupBy + "date"]: groupByDays,
                      data: [],
                    };
                  }
                }

                groupedData[groupByDays].data.push({
                  status,
                  id__count,
                  anatomy,
                  modality,
                });
              });

              const groupedDataList = Object.values(groupedData);
              return groupedDataList.map((itm) => {
                return {
                  date:
                    data_type.value === "recommendations"
                      ? itm[groupBy.replace("__", "")]
                      : itm[groupBy + "date"],
                  data: [...itm.data],
                };
              });
            } else {
              return data.map((itm) => {
                return {
                  date: itm[groupBy + "date"],
                  data: [itm],
                };
              });
            }
          } else if (frequency.value.includes("months")) {
            group = status
              ? group + "month" + "," + groupBy + "year" + "," + status
              : group + "month" + "," + groupBy + "year";
            orderingFilter = `&order_by=${groupBy}year,${groupBy}month`;

            const { data } = await API.getRequest(
              `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                startDate
              ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate).format(
                "YYYY-MM-DD"
              )}${str}${group}${orderingFilter}${extraParam}`
            );

            function getMonthName(month) {
              const months = [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ];

              if (month >= 1 && month <= 12) {
                return months[month - 1];
              } else {
                return "Invalid Month";
              }
            }

            if (
              data_type.value === "recommendations" ||
              data_type.value === "sms" ||
              data_type.value === "emails"
            ) {
              const groupedData = {};

              data.forEach((entry) => {
                const { status, id__count, modality, anatomy } = entry;
                let groupByMonths = entry[groupBy + "month"];
                let groupByYears = entry[groupBy + "year"];

                if (!groupedData[groupByMonths + " " + groupByYears]) {
                  groupedData[groupByMonths + " " + groupByYears] = {
                    [groupBy + "month"]: groupByMonths,
                    data: [],
                    [groupBy + "year"]: groupByYears,
                  };
                }

                groupedData[groupByMonths + " " + groupByYears].data.push({
                  status,
                  id__count,
                  anatomy,
                  modality,
                });
              });

              const groupedDataList = Object.values(groupedData);
              return groupedDataList.map((itm) => {
                return {
                  date:
                    getMonthName(itm[groupBy + "month"]) +
                    " " +
                    itm[groupBy + "year"],
                  data: [...itm.data],
                };
              });
            } else {
              return data.map((itm) => {
                return {
                  date:
                    getMonthName(itm[groupBy + "month"]) +
                    " " +
                    itm[groupBy + "year"],
                  data: [itm],
                };
              });
            }
          } else if (frequency.value.includes("weeks")) {
            group = status
              ? group + "week" + "," + groupBy + "year" + "," + status
              : group + "week" + "," + groupBy + "year";
            orderingFilter = `&order_by=${groupBy}year,${groupBy}week`;

            const { data } = await API.getRequest(
              `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                startDate
              ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate).format(
                "YYYY-MM-DD"
              )}${str}${group}${orderingFilter}${extraParam}`
            );
            function getDateFromWeek(weekNumber, year) {
              const startDate = moment()
                .year(year)
                .week(weekNumber)
                .startOf("week")
                .format("DD MMM YYYY");
              const endDate = moment()
                .year(year)
                .week(weekNumber)
                .endOf("week")
                .format("DD MMM YYYY");

              return `${startDate} - ${endDate}`;
            }

            if (
              data_type.value === "recommendations" ||
              data_type.value === "sms" ||
              data_type.value === "emails"
            ) {
              const groupedData = {};

              data.forEach((entry) => {
                const { status, id__count, anatomy, modality } = entry;
                let groupByWeeks = entry[groupBy + "week"];
                let groupByYears = entry[groupBy + "year"];

                if (!groupedData[groupByWeeks + " " + groupByYears]) {
                  groupedData[groupByWeeks + " " + groupByYears] = {
                    [groupBy + "week"]: groupByWeeks,
                    data: [],
                    [groupBy + "year"]: groupByYears,
                  };
                }

                groupedData[groupByWeeks + " " + groupByYears].data.push({
                  status,
                  id__count,
                  anatomy,
                  modality,
                });
              });

              const groupedDataList = Object.values(groupedData);
              return groupedDataList.map((itm) => {
                return {
                  date: getDateFromWeek(
                    itm[groupBy + "week"],
                    itm[groupBy + "year"]
                  ),
                  data: [...itm.data],
                };
              });
            } else {
              return data.map((itm) => {
                return {
                  date: getDateFromWeek(
                    itm[groupBy + "week"],
                    itm[groupBy + "year"]
                  ),
                  data: [itm],
                };
              });
            }
          } else if (frequency.value.includes("years")) {
            group = status ? group + "year" + "," + status : group + "year";
            orderingFilter = `&order_by=${groupBy}year`;

            const { data } = await API.getRequest(
              `${apiEndpoint}?agg_func=${agg}&${timeStampGte}=${moment(
                startDate
              ).format("YYYY-MM-DD")}&${timeStampLte}=${moment(endDate).format(
                "YYYY-MM-DD"
              )}${str}${group}${orderingFilter}${extraParam}`
            );

            if (
              data_type.value === "recommendations" ||
              data_type.value === "sms" ||
              data_type.value === "emails"
            ) {
              const groupedData = {};

              data.forEach((entry) => {
                const { status, id__count, anatomy, modality } = entry;
                let groupByYears = entry[groupBy + "year"];

                if (!groupedData[groupByYears]) {
                  groupedData[groupByYears] = {
                    [groupBy + "year"]: groupByYears,
                    data: [],
                  };
                }

                groupedData[groupByYears].data.push({
                  status,
                  id__count,
                  anatomy,
                  modality,
                });
              });

              const groupedDataList = Object.values(groupedData);
              return groupedDataList.map((itm) => {
                return {
                  date: itm[groupBy + "year"],
                  data: [...itm.data],
                };
              });
            } else {
              return data.map((itm) => {
                return {
                  date: itm[groupBy + "year"],
                  data: [itm],
                };
              });
            }
          }
        }
        if (
          data_type.value === "radiologists" ||
          data_type.value === "referrers"
        ) {
          let add =
            data_subset?.value === "quality"
              ? `&fields_completed_percentage=${100}`
              : "";
          let { data } = await API.getRequest(
            `${apiEndpoint}?agg_func=count&group_by=status${add}`
          );

          return data;
        } else if (data_type.value === "patients") {
          let { data } = await API.getRequest(
            `${apiEndpoint}?agg_func=count&group_by=${data_subset?.value}`
          );

          return data;
        } else if (data_type.value === "recommendations-pie") {
          let { data } = await API.getRequest(
            `${apiEndpoint}?agg_func=count&order_by=-id__count&group_by=${data_subset?.value}${str}`
          );
          const firstFive = data.slice(0, 5);
          const emptyLabel = "empty";

          const othersCount = data
            .slice(5)
            .reduce((sum, item) => sum + item.id__count, 0);

          if (data_subset?.value === "modality,anatomy") {
            return data?.length < 6
              ? firstFive.map((itm) => ({
                  ...itm,
                  "modality,anatomy":
                    (itm.modality ?? emptyLabel) +
                    " " +
                    (itm.anatomy ?? emptyLabel),
                }))
              : firstFive
                  .concat({
                    modality: "others",
                    anatomy: "",
                    id__count: othersCount,
                  })
                  .map((itm) => ({
                    ...itm,
                    "modality,anatomy":
                      (itm.modality ?? emptyLabel) +
                      " " +
                      (itm.anatomy ?? emptyLabel),
                  }));
          }

          return data?.length < 6
            ? firstFive.map((itm) => ({
                ...itm,
                [data_subset.value]: itm[data_subset.value] ?? emptyLabel,
              }))
            : firstFive
                .map((itm) => ({
                  ...itm,
                  [data_subset.value]: itm[data_subset.value] ?? emptyLabel,
                }))
                .concat({
                  [data_subset.value]: "others",
                  id__count: othersCount,
                });
        }
      }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const saveCustomChartData = (data,id) => async (dispatch) => {
  try {
     if(id){
      await API.patchRequest(`/api/v1/chart-builder-filters/${id}`, data);
     }else{
      await API.postRequest("/api/v1/chart-builder-filters/", data);
     }
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const getSavedCustomChartData = (page) => async (dispatch) => {
  try {
    const { data } = await API.getRequest(
      `/api/v1/chart-builder-filters/?page=${page}&ordering=-created`
    );
    return data;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const deleteSavedCustomChartData =
  (id) => async (dispatch, getState) => {
    try {
      await API.deleteRequest(`/api/v1/chart-builder-filters/${id}/`);
      dispatch(
        showAlert("success", "Success", "Custom chart deleted successfully!")
      );
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const updateSavedCustomChartData =
  ({ id, title }) =>
  async (dispatch, getState) => {
    try {
      await API.patchRequest(`/api/v1/chart-builder-filters/${id}/`, {
        title: title,
      });
      dispatch(
        showAlert("success", "Success", "Custom chart updated successfully!")
      );
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };


  export const getSavedCustomChartDataWithId = (id) => async (dispatch) => {
    try {
      const { data } = await API.getRequest(
        `/api/v1/chart-builder-filters/${id}/`
      );
      return data;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };