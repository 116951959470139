import React, { useEffect } from "react";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";

import { getMarketingAttribution } from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";
import { ReactComponent as MarketingAttributePatientIcon } from "../../../assets/patient-marketing-attribute-icon.svg";
import { ReactComponent as InfoTooltipIcon } from "../../../assets/info-tooltip-icon.svg";

import { Link } from "react-router-dom";
import { Tooltip, withStyles } from "@material-ui/core";

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 144px;
`;

const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #dfe0eb;
  width: 100%;
  padding-top: 18px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 78px;
  margin-bottom: 21px;
  background: #ffffff;
`;

const TitleLabel = styled.h2`
  font-weight: 700;
  font-size: 21px;
  color: #0c2945;
  margin-bottom: 0;
  font-family: Roboto Condensed;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 38px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 58px;
`;

const PatientsCount = styled.p`
  color: #b864c6;
  font-size: 40px;
  font-weight: 600;
  font-family: Roboto Condensed;
`;
const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: #00aeef;
`;

const StyledTooltip = (props) => {
  const { className, ...other } = props;

  return (
    <Tooltip
      {...other}
      PopperProps={{
        className: className,
        style: {
          backgroundColor: "#AEE3F8",
          marginBottom: "20px",
          borderRadius: "4px",
          zIndex: 3,
        },
      }}
    />
  );
};

const StyledTooltipWrapper = withStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "black!important",
    fontSize: "14px!important",
    fontWeight: 400 + "!important",
    width: "220px",
    padding: "10px",
    textAlign: "center",
    margin: "0px",
    position: "relative",
  },
}))(StyledTooltip);

const TitleRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const MarketingAttribution = () => {
  const dispatch = useDispatch();
  const marketingAttributionData = useSelector(
    (state) => state.radAdmin?.marketingAttribution?.data
  );

  const marketingAttributionDataLoading = useSelector(
    (state) => state.radAdmin?.marketingAttribution?.isLoading
  );

  useEffect(() => {
    dispatch(getMarketingAttribution());
  }, []);

  return (
    <Container>
      <TopContainer>
        <TitleContainer>
          <TitleRow>
            <TitleLabel>Converted</TitleLabel>
            <StyledTooltipWrapper
              placement="right"
              title={
                "Includes count of patients that have completed an action and have changed to an adherent, scheduled, or rx received status after we sent them a message"
              }
            >
              <InfoTooltipIcon style={{cursor:'pointer'}} />
            </StyledTooltipWrapper>
          </TitleRow>
          {
            !marketingAttributionDataLoading &&  marketingAttributionData?.id__count>0 &&
            <StyledLink
            to={
              "/auth/radAdmin/followUp/patients/?reco_status=adherent&reco_status=scheduled&reco_status=rx_received"
            }
          >
            View Patients
          </StyledLink>
          }
        
        </TitleContainer>
      </TopContainer>
      <CardsContainer>
        {marketingAttributionDataLoading ? (
          <SpinnerWrapper>
            <Spinner role="status" animation="border" size="sm" />
          </SpinnerWrapper>
        ) : (
          <>
            <PatientsCount>
              {marketingAttributionData?.id__count ?? 0} Patients
            </PatientsCount>
            <MarketingAttributePatientIcon />
          </>
        )}
      </CardsContainer>
    </Container>
  );
};

export default MarketingAttribution;
